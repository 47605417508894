// extracted by mini-css-extract-plugin
export var avatar = "style-module--avatar--8ab13";
export var column = "style-module--column--a28d1";
export var description = "style-module--description--c6ee9";
export var gray = "style-module--gray--33013";
export var image = "style-module--image--bdb1a";
export var info = "style-module--info--26ea8";
export var large = "style-module--large--082e9";
export var name = "style-module--name--4935c";
export var opacityEnter = "style-module--opacityEnter--ac36b";
export var opacityExit = "style-module--opacityExit--60fc7";
export var regular = "style-module--regular--ba291";
export var rollDown = "style-module--rollDown--ef256";
export var rollUp = "style-module--rollUp--eeb86";
export var row = "style-module--row--df209";
export var slideInDown = "style-module--slideInDown--b6fb7";
export var slideOutUp = "style-module--slideOutUp--fe74a";
export var splashEnter = "style-module--splashEnter--d8151";
export var splashExit = "style-module--splashExit--eaa91";
export var white = "style-module--white--885e8";