import React from 'react';
import AvatarImage from '../AvatarImage';
import { AvatarTypes } from './Avatar.types';

import * as styles from './style.module.scss';

export default function Avatar({
  image,
  title,
  info,
  className,
  size = 'regular',
  layout = 'row',
  color = 'gray',
  imageOptions,
  isImageFixedSize = false,
}: AvatarTypes) {
  return (
    <div
      className={`${styles.avatar} ${layout === 'column' ? styles.column : styles.row} ${styles[size]} ${
        styles[color]
      } ${className || ''}`}
    >
      <AvatarImage size={size} image={image} options={imageOptions} isFixedSize={isImageFixedSize} />
      <div className={styles.description} data-image={String(!!image?.image)}>
        <p className={styles.name}>{title}</p>
        {info && <p className={styles.info}>{info}</p>}
      </div>
    </div>
  );
}
